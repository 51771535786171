<template lang="pug">
  div
    vs-sidebar.items-no-padding(position-right='' v-model='active' parent='body' default-index='55555551')
      .h-100(v-if='isLoadingLease || isUpdatingLease || isDeletingLease')
        .text-center.flex-center.h-100
          .spinner-border.text-primary(role='status')
            span.sr-only Chargement...
          br
          |                 Chargement des d&eacute;tails du bail...
      .h-full(v-else)
        .sidebar-header.py-1.px-2
          div
            h3 {{lease.label}}
            small(v-show='lease.matricule') Matricule : {{lease.code}}
          feather-icon.close-icon.cursor-pointer(icon='XIcon' size='24' @click.stop='active =!active')
        vs-divider.m-0
        .d-flex.justify-content-between.sidebar-action.py-05.px-2.w-100
          .d-flex
            b-button.btn-icon( variant='primary'  @click='editLease(lease.id)' :disabled='archived' lang="html" class="")
              <feather-icon icon='ExternalLinkIcon' /> Consulter
          .d-flex
            b-button.btn-icon(v-if='archived' variant='warning' @click='restoreLeaseLocal(lease.id, lease.name)')
              feather-icon(icon='ArrowDownIcon')
                | Restaurer
            b-button.btn-icon(v-else variant='outline-primary'  @click='editLease(lease.id)' :disabled='archived' lang="html" class="no-border-right no-border-radius-right")
              <feather-icon icon='Edit2Icon' /> Modifier
            b-button-group
              b-button.btn-icon(v-if='archived' variant='outline-danger' @click='deleteLeaseLocal(lease.id, lease.name)')
                feather-icon(icon='TrashIcon')
                  | Supprimer d&eacute;finitivement
              b-button.btn-icon(v-else variant='outline-danger' @click='archiveLeaseLocal(lease.id, lease.name)'  class="no-border-radius-left")
                feather-icon(icon='ArchiveIcon')
        vs-divider.m-0
        .sidebar-content
          .w-full.d-flex.align-items-center
            feather-icon(icon='ChevronRightIcon' size='22')
            span.text-primary.h3  Bailleur
          .w-full.d-flex.justify-content-between.mt-1
            .landlord-address.d-flex.flex-column
              span.text-uppercase {{lease.landlordName}}
              span {{lease.landlordAddress}}
              span(v-if="lease.landlordAddressComplement") {{lease.landlordAddressComplement}}
              span {{lease.landlordZipCode}} {{lease.landlordCity}}
          vs-divider
          .w-full.d-flex.align-items-center
            feather-icon(icon='ChevronRightIcon' size='22')
            span.text-primary.h3  Locataire
          .w-full.d-flex.justify-content-between.mt-1
            .tenant-address.d-flex.flex-column
              span.text-uppercase {{lease.tenantName}}
              span {{lease.tenantAddress}}
              span(v-if="lease.tenantAddressComplement") {{lease.tenantAddressComplement}}
              span {{lease.tenantZipCode}} {{lease.tenantCity}}
          vs-divider
          .w-full.d-flex.align-items-center
            feather-icon(icon='ChevronRightIcon' size='22')
            span.text-primary.h3  Données financières
          .w-full.d-flex.mt-1.flex-column
            .table-financial-data.column.w-100.d-flex.justify-content-between
                span Type de bail
                span {{lease.leaseType ? lease.leaseType.label : "N/A"}}
            .table-financial-data.column.w-100.d-flex.justify-content-between
                span Périodicité
                span {{lease.periodicity ? lease.periodicity.label : "N/A"}}
            .table-financial-data.column.w-100.d-flex.justify-content-between
                span Indice de révision
                span {{lease.leaseType ? lease.leaseType.label : "N/A"}}
            .table-financial-data.column.w-100.d-flex.justify-content-between
                span Loyer
                span {{lease.rentHcHt ? lease.rentHcHt : "N/A"}}
            .table-financial-data.column.w-100.d-flex.justify-content-between
                span Charges
                span {{lease.chargeHt ? lease.chargeHt : "N/A"}}
          vs-divider
          .w-full.d-flex.align-items-center
            feather-icon(icon='ChevronRightIcon' size='22')
            span.text-primary.h3  Adresse du bien
          .w-full.d-flex.justify-content-between.mt-1
            .tenant-address.d-flex.flex-column
              span {{lease.propertyAddress}}
              span(v-if="lease.propertyAddressComplement") {{lease.propertyAddressComplement}}
              span {{lease.propertyZipCode}} {{lease.propertyCity}}




</template>


<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { mapGetters, mapActions } from 'vuex'

export default {
  data () {
    return {
      archived:false,
      lease:{}
    }
  },
  computed: {
    ...mapGetters([
      "isUpdatingLease",
      "isLoadingLease",
      "isDeletingLease",
      "isOpenSidebarLease",
    ]),
    active: {
      get ()    { return this.isOpenSidebarLease },
      set (val) { this.$store.commit('SET_IS_OPEN_SIDEBAR_LEASE', val) }
    },
  },
  methods: {
    ...mapActions(["updateLease", "deleteLease", "isArchivingLease", "archiveLease", "fetchLease", "fetchLeaseArchived","restoreLease"]),

    getLeaseArchivedDetails(id, archived) {
      this.archived=archived
      if (id != '') {
        this.fetchLeaseArchived(id).then(res => {
          this.lease = res
        })
      } else {
        this.lease={}
      }
    },
    getLeaseDetails(id) {
      this.archived=false
      if (id != '') {
        this.fetchLease(id).then(res => {
          this.lease = res
        })
      } else {
        this.lease={}
      }
    },

    editLease(id){
        this.$store.commit('SET_IS_OPEN_SIDEBAR_LEASE', false)
        this.$router.push({ name: 'edit-lease', params: { id: id, title: "Edit : "+ this.lease.name, tips:"Editer le collaborateur : "+this.lease.name, routeOrigine:'leases' } })
    },
    editCompany(id){
        this.$store.commit('SET_IS_OPEN_SIDEBAR_LEASE', false)
        this.$router.push({ name: 'edit-company', params: { id: id, title: "Edit : "+ (this.lease.company.name ? this.lease.company.name : this.lease.company.firstName + ' ' + this.lease.company.lastName), tips:"Editer l\'entreprise : "+(this.lease.company.name ? this.lease.company.name : this.lease.company.firstName + ' ' + this.lease.company.lastName), routeOrigine:'leases' } })
    },

    archiveLeaseLocal (id, label) {
      this.$bvModal
        .msgBoxConfirm('Vous pourrez à tout moment restaurer ce bail en consultant la liste de vos archives.', {
          title:
            'Êtes-vous sûr de vouloir archiver le bail "' + label + '" ?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Archiver',
          cancelTitle: 'Annuler',
          cancelVariant: 'outline-primary',
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.archiveLease(id);
            this.$store.commit('SET_IS_OPEN_SIDEBAR_LEASE', false)
          }
        })
    },
    restoreLeaseLocal (id, label) {
      this.$bvModal
        .msgBoxConfirm('Vous pourez ré-utiliser cette fiche dans tous les modules de votre application.', {
          title:
            'Êtes-vous sûr de vouloir restaurer le bail "' + label + '" ?',
          size: 'sm',
          okVariant: 'warning',
          okTitle: 'Restaurer',
          cancelTitle: 'Annuler',
          cancelVariant: 'outline-primary',
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.restoreLease(id);
            this.$store.commit('SET_IS_OPEN_SIDEBAR_LEASE', false)
          }
        })
    },
    deleteLeaseLocal (id, label) {
      this.$bvModal
        .msgBoxConfirm('Cette action est définitive et irréversible.', {
          title:
            'Êtes-vous sûr de vouloir supprimer le bail "' + label + '" ?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Supprimer',
          cancelTitle: 'Annuler',
          cancelVariant: 'outline-primary',
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.deleteLease(id)
            this.$store.commit('SET_IS_OPEN_SIDEBAR_LEASE', false)
          }
        })
    },
  },
  components: {
    VuePerfectScrollbar
  }
}
</script>

<style>
  .no-border-radius-right {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .no-border-radius-left {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .no-border-right{
    border-right: none !important;
  }
  .h3{
    margin:0
  }
  .flex-column > .table-financial-data{
       padding:5px 10px 5px 10px;
  }
  .flex-column > .table-financial-data:nth-child(odd){
    background-color: #EEF1F6;
  }
</style>
